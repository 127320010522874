body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #333;
  background-color: #f4f4f4;
  box-sizing: border-box;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f4f4f4;
  border-bottom: 2px solid #000;
}

.header p {
  margin: 0;
  font-size: 2em;
  color: #333;
}

.container {
  margin: 20px;
}

.user-info {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 10px;
}

.user-info p {
  margin: 0;
  text-align: left;
}

.user-info p:first-child {
  margin: 0;
  text-align: left;
  font-weight: bold;
}

.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 3%;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.mesh-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #0066CC;
  border-radius: 8px;
  color: #3866DE;
  padding: 10px;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;
  font-weight: 500;
}

.mesh-button img {
  padding-right: 10px;
  width: 25px;
}

.mesh-button:hover {
  background-color: #3866DE;
  color: #ffffff;
}
